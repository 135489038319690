import React from 'react'
import shortid from 'shortid'
import styles from './Summary.module.css'

interface SummaryItem {
  title: string
  text: string
  icon: {
    publicURL: string
  }
  comingsoon?: string
}

interface SummaryProps {
  summary: SummaryItem[]
}

const Summary = ({ summary }: SummaryProps) => (
  <ul className={styles.summary}>
    {summary &&
      summary.map((item: SummaryItem) => (
        <li
          className={`${styles.item} ${item.comingsoon && styles.comingsoon}`}
          key={shortid.generate()}
        >
          <img className={styles.graphic} src={item.icon.publicURL} />
          <h4>{item.title}</h4>
          <p>{item.text}</p>
          {item.comingsoon && <em>{item.comingsoon}</em>}
        </li>
      ))}
  </ul>
)

export default Summary
