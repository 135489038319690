import React from 'react'
import shortid from 'shortid'
import { graphql, useStaticQuery } from 'gatsby'
import { formatNum } from '../../../../utils'
import stylesIndex from './index.module.css'
import styles from './Metrics.module.css'
import NumberUnit from '../../../molecules/NumberUnit'
import Contract from '../Contract'

export interface MetricsContent {
  title: string
  value: string
}

const query = graphql`
  query MetricsQuery {
    metrics: allFile(
      filter: { relativePath: { eq: "pages/token/header.json" } }
    ) {
      edges {
        node {
          childTokenJson {
            metrics {
              title
              value
            }
          }
        }
      }
    }
  }
`

export default function Metrics() {
  const data = useStaticQuery(query)
  const content = data.metrics.edges[0].node.childTokenJson
  const { metrics } = content

  return (
    <div className={styles.metrics}>
      <div className={stylesIndex.numberGrid}>
        {metrics.map((item: MetricsContent) => (
          <NumberUnit
            key={shortid.generate()}
            small
            label={item.title}
            value={
              isNaN(Number(item.value))
                ? item.value
                : formatNum(item.value, '0.00a')
            }
          />
        ))}
      </div>
      <Contract className={styles.contract} />
    </div>
  )
}
