import React from 'react'
import styles from './Contract.module.css'
import { useSiteMetadata } from '../../../hooks/useSiteMetadata'
import { ReactComponent as External } from '../../../images/external.svg'
import Copy from '../../atoms/Copy'

export default function Contract({ className }: { className?: string }) {
  const { oceanTokenContract } = useSiteMetadata()

  return (
    <div className={`${styles.contract} ${className && className}`}>
      <div>
        <code>{oceanTokenContract}</code>
        <Copy text={oceanTokenContract} className={styles.action} />
        <a
          href={`https://etherscan.io/token/${oceanTokenContract}`}
          target="_blank"
          rel="noopener noreferrer"
          title="View on Etherscan"
          className={styles.action}
        >
          <External />
        </a>
      </div>

      <footer>Contract Address</footer>
    </div>
  )
}
