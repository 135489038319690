import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Section from '../../../molecules/Section'
import LinkCards from './LinkCards'
import { LinkList } from '../../../atoms/Lists'
import styles from './index.module.css'
import Contract from '../Contract'
import CryptoWidget from '../../../atoms/CryptoWidget'

const query = graphql`
  query GetAndStoreQuery {
    getAndStore: allFile(
      filter: { relativePath: { eq: "pages/token/get.json" } }
    ) {
      edges {
        node {
          childTokenJson {
            title
            description
            exchanges {
              title
              items {
                title
                url
                highlight
                logo {
                  publicURL
                  name
                }
              }
            }
            store {
              title
              description
              items {
                title
                url
                logo {
                  publicURL
                  name
                }
              }
            }
            tokendetails {
              details {
                name
                value
              }
              docs {
                title
                url
              }
            }
          }
        }
      }
    }
  }
`

export default function GetAndStore() {
  const data = useStaticQuery(query)
  const content = data.getAndStore.edges[0].node.childTokenJson
  const { title, description, exchanges, store, tokendetails } = content

  return (
    <Section id="get" title={title} description={description} headerCenter>
      <div className={styles.exchanges}>
        <h3>{exchanges.title}</h3>
        <LinkCards
          items={exchanges.items}
          large
          otherComponent={<CryptoWidget coins={['ocean-protocol']} />}
        />
      </div>

      <div className={styles.details}>
        <div>
          <h3>{store.title}</h3>
          <p>{store.description}</p>
          <LinkCards items={store.items} />
          <LinkList links={tokendetails.docs} />
        </div>
        <div>
          <div className={styles.tokenDetails}>
            {tokendetails.details.map(
              (detail: { name: string; value: string }) => (
                <p key={detail.name}>
                  <strong>{detail.value}</strong>
                  {detail.name}
                </p>
              )
            )}
            <Contract />
          </div>
        </div>
      </div>
    </Section>
  )
}
