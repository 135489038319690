import React from 'react'
import shortid from 'shortid'
import Img, { FluidObject } from 'gatsby-image'
import { LinkList } from '../../../atoms/Lists'
import styles from './FeaturesList.module.css'

export interface FeatureItem {
  title: string
  text: string
  graphic: { childImageSharp: { fluid: FluidObject } }
  comingsoon?: string
  actions?: {
    title: string
    url: string
  }[]
}

export default function FeaturesList({
  features
}: {
  features: FeatureItem[]
}) {
  return (
    <>
      {features.map((feature) => (
        <div
          className={`${styles.grid} ${
            feature.comingsoon && styles.comingsoon
          }`}
          key={shortid.generate()}
        >
          <div>
            <h3>{feature.title}</h3>
            <p>{feature.text}</p>

            {feature.actions && (
              <div className={styles.actions}>
                <LinkList links={feature.actions} />
              </div>
            )}

            {feature.comingsoon && <em>{feature.comingsoon}</em>}
          </div>
          <Img fluid={feature.graphic.childImageSharp.fluid} />
        </div>
      ))}
    </>
  )
}
