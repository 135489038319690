import React from 'react'
import shortid from 'shortid'
import { graphql, useStaticQuery } from 'gatsby'
import Section from '../../molecules/Section'
import { LinkList } from '../../atoms/Lists'
import stylesIndex from './index.module.css'
import styles from './Tools.module.css'

const query = graphql`
  query ToolsQuery {
    tools: allFile(filter: { relativePath: { eq: "pages/token/tools.json" } }) {
      edges {
        node {
          childTokenJson {
            title
            description
            tools {
              title
              text
              url
            }
            links {
              title
              url
            }
            add {
              title
              url
              description
            }
          }
        }
      }
    }
  }
`

export default function Tools() {
  const data = useStaticQuery(query)
  const content = data.tools.edges[0].node.childTokenJson
  const { title, description, tools, links, add } = content

  return (
    <Section id="tools" title={title} description={description} headerCenter>
      <div className={styles.grid}>
        <div>
          {tools.map((tool: { title: string; text: string; url: string }) => (
            <a
              className={`${stylesIndex.action} ${styles.toolCard}`}
              href={tool.url}
              key={shortid.generate()}
            >
              <h3 className={styles.toolTitle}>{tool.title}</h3>
              <p>{tool.text}</p>
            </a>
          ))}
        </div>
        <div>
          <LinkList links={links} />
        </div>
      </div>

      <a className={styles.add} href={add.url}>
        <h3>{add.title}</h3>
        <p dangerouslySetInnerHTML={{ __html: add.description }} />
      </a>
    </Section>
  )
}
