import axios from 'axios'
import { fromWei } from 'ethjs-unit'

export async function fetchData(url: string) {
  try {
    const response = await axios(url)

    if (response.status !== 200) {
      return console.error('Non-200 response: ' + response.status)
    }
    return response.data
  } catch (error) {
    console.error(error.message)
  }
}

export async function fetchPrices() {
  const currencies = 'btc,eth,usd,eur'
  const json = await fetchData(
    `https://api.coingecko.com/api/v3/simple/price?ids=ocean-protocol&vs_currencies=${currencies}`
  )

  const { btc, eth, usd, eur } = json['ocean-protocol']
  const prices = { ocean: 1, btc, eth, usd, eur }
  return prices
}

export async function getBalances(account: string, prices: any) {
  const json = await fetchData(`/api/etherscan/${account}`)

  const ocean = Number(fromWei(json.result, 'ether'))
  const eth = ocean * prices.eth
  const btc = ocean * prices.btc
  const usd = ocean * prices.usd
  const eur = ocean * prices.eur

  return { ocean, eth, btc, usd, eur }
}

export const defaultBalances = {
  ocean: 0,
  eth: 0,
  btc: 0,
  usd: 0,
  eur: 0
}
