import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Section from '../../../molecules/Section'
import FormTokens from './FormTokens'
import styles from './index.module.css'
import { markdownify } from '../../../../utils'

const query = graphql`
  query BalanceQuery {
    balance: allFile(
      filter: { relativePath: { eq: "pages/token/balance.json" } }
    ) {
      edges {
        node {
          childTokenJson {
            title
            description
            note {
              title
              text
            }
          }
        }
      }
    }
  }
`

export default function Balance() {
  const data = useStaticQuery(query)
  const content = data.balance.edges[0].node.childTokenJson
  const { title, description, note } = content

  return (
    <Section id="balance" className={styles.section}>
      <div className={styles.grid}>
        <header>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.description}>{description}</p>
        </header>
        <div>
          <FormTokens />
        </div>
      </div>
      <aside className={styles.note}>
        <h3>{note.title}</h3>
        {markdownify(note.text)}
      </aside>
    </Section>
  )
}
