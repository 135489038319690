import React, { ReactElement, useEffect, useState } from 'react'
import styles from './CryptoWidget.module.css'

export default function CryptoWidget({
  coins
}: {
  coins: string[]
}): ReactElement {
  const [contentRef, setContentRef] = useState(null)

  useEffect(() => {
    if (!contentRef?.contentWindow?.document?.body) return
    const iframe = document.getElementById('CryptoWigdetIframe')
    const script = (iframe as any).contentWindow.document.createElement(
      'script'
    )
    script.setAttribute('id', 'cryptoWidgetScript')
    script.src = 'https://crypto.com/price/static/widget/index.js'
    ;(iframe as any).contentWindow.document.body.appendChild(script)
    return () => {
      ;(iframe as any).parentNode.removeChild(iframe as any)
    }
  }, [contentRef])

  useEffect(() => {
    if (!coins || !contentRef?.contentWindow?.document?.body) return
    let newCoinsString = ''
    coins.forEach((coin: string) => {
      newCoinsString += coin + ','
    })
    newCoinsString = newCoinsString.slice(0, -1)
    const iframe = document.getElementById('CryptoWigdetIframe')
    iframe.setAttribute('class', styles.iframe)
    const divEl = (iframe as any).contentWindow.document.createElement('div')
    divEl.id = 'crypto-widget-CoinList'
    divEl.setAttribute('data-design', 'classic')
    divEl.setAttribute('data-coins', newCoinsString)
    ;(iframe as any).contentWindow.document.body?.appendChild(divEl)
  }, [coins, contentRef])

  return <iframe id="CryptoWigdetIframe" ref={setContentRef} />
}
