import React from 'react'
import loadable from '@loadable/component'
import Metrics from './Metrics'
import Market from './Market'
import stylesGraph from './Graph.module.css'

const Graph = loadable(() => import('./Graph'))

export default function Header() {
  return (
    <>
      <Metrics />

      <figure className={stylesGraph.graphWrap}>
        <Graph />
      </figure>

      <Market />
    </>
  )
}
