import React from 'react'
import { formatNum } from '../../../../utils'
import styles from './FormOutput.module.css'
import { useSiteMetadata } from '../../../../hooks/useSiteMetadata'

interface OutputProps {
  balances: {
    ocean: number | string
    eth: number | string
    btc: number | string
    usd: number | string
    eur: number | string
  }
  address?: string
  hasResponse: boolean
}

export default function Output({
  balances,
  address,
  hasResponse
}: OutputProps) {
  const { oceanTokenContract } = useSiteMetadata()

  return (
    <fieldset className={styles.output}>
      <div className={styles.balanceMain}>
        Ọ {formatNum(balances.ocean, '0.0000')}
      </div>
      <div className={styles.conversions}>
        <span title="Bitcoin @coingecko">
          = Ƀ {formatNum(balances.btc, '0,0.000000')}
        </span>
        <span title="Ether @coingecko">
          = Ξ {formatNum(balances.eth, '0,0.0000')}
        </span>
        <span title="US Dollar @coingecko">
          = $ {formatNum(balances.usd, '0,0.00')}
        </span>
      </div>

      {hasResponse && (
        <a
          href={`https://etherscan.io/token/${oceanTokenContract}?a=${address}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Etherscan
        </a>
      )}
    </fieldset>
  )
}
