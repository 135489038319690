import React, { ReactElement } from 'react'
import loadable from '@loadable/component'
import styles from './Copy.module.css'
import { ReactComponent as CopyIcon } from '../../images/copy.svg'

const Clipboard = loadable(() => import('react-clipboard.js'))

const onCopySuccess = (e: any) => {
  e.trigger.classList.add(styles.copied)
}

export default function Copy({
  text,
  className
}: {
  text: string
  className?: string
}): ReactElement {
  return (
    <Clipboard
      data-clipboard-text={text}
      button-title="Copy to clipboard"
      onSuccess={(e: ClipboardJS.Event) => onCopySuccess(e)}
      className={`${styles.button} ${className && className}`}
    >
      <CopyIcon />
    </Clipboard>
  )
}
