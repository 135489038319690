import React from 'react'
import NumberUnit from '../../../../molecules/NumberUnit'
import { MarketData } from './getMarketData'
import styles from './index.module.css'

const NumberWithChange = ({
  value,
  change
}: {
  value: number | string
  change: number | string
}) => {
  const color =
    Number(change) < 0 ? 'var(--brand-alert-red)' : 'var(--brand-alert-green)'

  return (
    <>
      {value}
      <span className={styles.change} style={{ color }} title="24 hrs change">
        {Number(change) > 0 && '+'}
        {change}%
      </span>
    </>
  )
}

export function NumberMarkup({
  data,
  item
}: {
  data: MarketData
  item: string
}) {
  const value = item.includes('Price (USD)') ? (
    <NumberWithChange
      value={data.price.usd.value}
      change={data.price.usd.change}
    />
  ) : item.includes('BTC') ? (
    <NumberWithChange
      value={data.price.btc.value}
      change={data.price.btc.change}
    />
  ) : item.includes('Volume') ? (
    data.volume
  ) : (
    data.marketCap
  )
  return <NumberUnit small label={item} value={value} />
}
