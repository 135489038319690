import React from 'react'
import shortid from 'shortid'
import classNames from 'classnames/bind'
import stylesIndex from '../index.module.css'
import styles from './LinkCards.module.css'

const cx = classNames.bind(styles)

export interface LinkCardsItem {
  title: string
  url: string
  highlight?: boolean
  logo: {
    publicURL: string
    name: string
  }
}

export interface LinkCardsProps {
  items: LinkCardsItem[]
  large?: boolean
  otherComponent?: any
}

export default function LinkCards({
  items,
  large,
  otherComponent
}: LinkCardsProps) {
  return (
    <div className={`${styles.linkCards} ${large && styles.large}`}>
      {items &&
        items.map((item) => {
          const logoClasses = cx({
            logo: true,
            [item.logo.name]: true
          })

          return (
            <a
              className={`${stylesIndex.action} ${
                item.highlight && styles.highlight
              }`}
              href={item.url}
              key={shortid.generate()}
              title={item.title}
            >
              <img
                className={logoClasses}
                src={item.logo.publicURL}
                alt={item.title}
                height={25}
              />
            </a>
          )
        })}
      {otherComponent}
    </div>
  )
}
