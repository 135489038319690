import React from 'react'
import Time from '../../../../atoms/Time'
import styles from './LastUpdate.module.css'

export default function LastUpdate({ date }: { date: number }) {
  return (
    <div className={styles.lastUpdate}>
      Data from{' '}
      <a href="https://www.coingecko.com/en/coins/ocean-protocol">CoinGecko</a>{' '}
      updated <Time date={new Date(date).toString()} />.
    </div>
  )
}
