import React, { useState, useEffect } from 'react'
import shortid from 'shortid'
import { graphql, useStaticQuery } from 'gatsby'
import { formatNum } from '../../../../../utils'
import { NumberMarkup } from './NumberMarkup'
import getMarketData, { MarketData } from './getMarketData'
import LastUpdate from './LastUpdate'
import stylesIndex from '../index.module.css'
import styles from './index.module.css'
import EtherscanLink from '../../EtherscanLink'

const query = graphql`
  query MarketQuery {
    market: allFile(
      filter: { relativePath: { eq: "pages/token/header.json" } }
    ) {
      edges {
        node {
          childTokenJson {
            market
            marketLinks {
              title
              link
            }
          }
        }
      }
    }
  }
`

const initialState = {
  price: {
    usd: {
      value: 0,
      change: 0
    },
    btc: {
      value: 0,
      change: 0
    }
  },
  marketCap: 0,
  volume: 0,
  lastUpdate: 0
}

export default function Market() {
  const contentData = useStaticQuery(query)
  const content = contentData.market.edges[0].node.childTokenJson
  const { market, marketLinks } = content
  const [data, setData] = useState<MarketData>(initialState)

  async function getData() {
    try {
      const { price, marketCap, volume, lastUpdate } = await getMarketData()

      setData({
        price: {
          usd: {
            value: `${formatNum(price.usd.value, '($ 0.0000 a)')}`,
            change: `${formatNum(price.usd.change, '0.0')}`
          },
          btc: {
            value: `₿ ${formatNum(price.btc.value, '(0.00000000 a)')}`,
            change: `${formatNum(price.btc.change, '0.0')}`
          }
        },
        marketCap: `${formatNum(marketCap, '($ 0.00 a)')}`,
        volume: `${formatNum(volume, '($ 0.00 a)')}`,
        lastUpdate: lastUpdate * 1000 // convert from epoch to ms
      })
    } catch (error) {
      console.error(error.message)
    }
  }

  useEffect(() => {
    getData()
    // update every 60s
    const timer = setInterval(getData, 60000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <aside className={styles.market}>
      <ul className={stylesIndex.numberGrid}>
        {market.map((item: string) => (
          <NumberMarkup data={data} item={item} key={shortid.generate()} />
        ))}
        <li>
          {marketLinks.map((link: { title: string; link: string }) => (
            <a
              className={styles.marketLink}
              key={shortid.generate()}
              href={link.link}
            >
              {link.title}
            </a>
          ))}
          <EtherscanLink className={styles.marketLink} />
        </li>
      </ul>
      <LastUpdate date={data.lastUpdate} />
    </aside>
  )
}
