import React from 'react'
import { PageProps, graphql } from 'gatsby'
import Layout from '../components/Layout'
import PageToken from '../components/pages/token'
import Header from '../components/pages/token/Header'
import tokenSwapNotificationContent from '../components/atoms/TokenSwapNotification'

export default function PageGatbsyToken(props: PageProps) {
  const content = (props.data as any).content.edges[0].node.childTokenJson
  const { title, description, actions } = content

  return (
    <Layout
      title={title}
      description={description}
      uri={props.uri}
      location={props.location}
      heroNotification={tokenSwapNotificationContent()}
      heroActions={actions}
      heroContent={<Header />}
      heroNoWaves
      heroStyle={{
        paddingBottom: 'var(--spacer)'
      }}
    >
      <PageToken />
    </Layout>
  )
}

export const contentQuery = graphql`
  query TokenPageQuery {
    content: allFile(
      filter: { relativePath: { eq: "pages/token/header.json" } }
    ) {
      edges {
        node {
          childTokenJson {
            title
            description
            actions {
              title
              url
            }
          }
        }
      }
    }
  }
`
