import React, { ReactElement } from 'react'
import loadable from '@loadable/component'

const LazyDate = loadable.lib(() => import('date-fns'))

function TimeMarkup({
  date,
  format,
  formatDistance,
  className
}: {
  date: Date
  format?: any
  formatDistance?: any
  className?: string
}) {
  const dateIso = date.toISOString()

  return (
    <time
      title={format ? format(date, 'yyyy/MM/dd HH:mm') : dateIso}
      dateTime={dateIso}
      className={className && className}
    >
      {format ? formatDistance(date, Date.now(), { addSuffix: true }) : dateIso}
    </time>
  )
}

export default function Time({
  date,
  className
}: {
  date: string | Date
  className?: string
}): ReactElement {
  const dateNew = new Date(date)

  return (
    <LazyDate fallback={<TimeMarkup date={dateNew} className={className} />}>
      {({ format, formatDistance }) => (
        <TimeMarkup
          date={dateNew}
          format={format}
          formatDistance={formatDistance}
          className={className}
        />
      )}
    </LazyDate>
  )
}
