import React from 'react'
import GetAndStore from './GetAndStore'
import Tools from './Tools'
import Tokenomics from './Tokenomics'
import Balance from './Balance'

export default function PageToken() {
  return (
    <>
      <GetAndStore />
      <Balance />
      <Tools />
      <Tokenomics />
    </>
  )
}
