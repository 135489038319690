/* eslint-disable camelcase */
import axios from 'axios'

interface Price {
  usd: {
    value: number | string
    change: number | string
  }
  btc: {
    value: number | string
    change: number | string
  }
}

export interface MarketData {
  price: Price
  marketCap: number | string
  volume: number | string
  lastUpdate: number
}

export const url =
  'https://api.coingecko.com/api/v3/simple/price?ids=ocean-protocol&vs_currencies=usd,btc&include_market_cap=true&include_24hr_change=true&include_24hr_vol=true&include_last_updated_at=true'

export default async function getMarketData(): Promise<MarketData> {
  try {
    const response = await axios(url)
    const {
      usd,
      btc,

      usd_market_cap,
      usd_24h_vol,
      usd_24h_change,
      btc_24h_change,
      last_updated_at
    } = response.data && response.data['ocean-protocol']

    return {
      price: {
        usd: {
          value: usd,
          change: usd_24h_change
        },
        btc: {
          value: btc,
          change: btc_24h_change
        }
      },
      marketCap: usd_market_cap,
      volume: usd_24h_vol,
      lastUpdate: last_updated_at
    }
  } catch (error) {
    console.error(error.message)
  }
}
