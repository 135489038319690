import React, { useState, FormEvent } from 'react'
import ethereumAddress from 'ethereum-address'
import loadable from '@loadable/component'
import Button from '../../../atoms/Button'
import { defaultBalances, fetchPrices, getBalances } from './utils'
import Output from './FormOutput'
import styles from './FormTokens.module.css'
import stylesIndex from '../index.module.css'
import Input from '../../../atoms/Input'

const Alert = loadable(() => import('../../../atoms/Alert'))

export default function FormTokens() {
  const [balances, setBalances] = useState(defaultBalances)
  const [isLoading, setIsLoading] = useState(false)
  const [address, setAddress] = useState('')
  const [error, setError] = useState<string | null>()
  const [hasResponse, setHasResponse] = useState(false)

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()

    try {
      setError(null)
      setIsLoading(true)
      const prices = await fetchPrices()
      const balances = await getBalances(address, prices)
      setBalances(balances)
      setHasResponse(true)
    } catch (error) {
      console.error(error.message)
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  function validateField(value: string) {
    setError(null)

    if (value.length > 5) {
      ethereumAddress.isAddress(value)
        ? setError(null)
        : setError(`${value} is not a valid Ethereum wallet address.`)
    }

    if (value === '') {
      setError(null)
      setBalances(defaultBalances)
    }
  }

  function handleUserInput(e: any) {
    const { value } = e.target
    setAddress(value)
    validateField(value)
  }

  return (
    <form className={`${styles.balance} ${stylesIndex.action}`}>
      <Output balances={balances} address={address} hasResponse={hasResponse} />

      <fieldset className={styles.input}>
        <Input
          type="text"
          name="walletAddress"
          label="Your Wallet Address"
          placeholder="i.e. 0xG790225b742411CFC84742bC2DC1h1736591811d"
          value={address}
          required
          disabled={isLoading}
          onChange={handleUserInput}
        />
        <Button
          style="primary"
          disabled={!address || isLoading}
          onClick={handleSubmit}
        >
          Get Balance
        </Button>
      </fieldset>

      {error && <Alert severity="error">{error}</Alert>}
    </form>
  )
}
