import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { LinkList } from '../../../atoms/Lists'
import Section from '../../../molecules/Section'
import FeaturesList from './FeaturesList'
import Summary from './Summary'
import styles from './index.module.css'

const query = graphql`
  query TokenomicsQuery {
    tokenomics: allFile(
      filter: { relativePath: { eq: "pages/token/tokenomics.json" } }
    ) {
      edges {
        node {
          childTokenJson {
            title
            description
            summary {
              title
              text
              icon {
                publicURL
              }
            }
            features {
              title
              text
              graphic {
                childImageSharp {
                  fluid(maxWidth: 408) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              comingsoon
              actions {
                title
                url
              }
            }
            footer {
              title
              actions {
                title
                url
              }
            }
          }
        }
      }
    }
  }
`

export default function Tokenomics() {
  const data = useStaticQuery(query)
  const content = data.tokenomics.edges[0].node.childTokenJson
  const { title, description, summary, features, footer } = content

  return (
    <Section
      id="tokenomics"
      title={title}
      description={description}
      headerCenter
      className={styles.section}
    >
      <Summary summary={summary} />
      <FeaturesList features={features} />

      <div className={styles.grid}>
        <div>
          <h3>{footer.title}</h3>
        </div>
        <div>
          <LinkList links={footer.actions} />
        </div>
      </div>
    </Section>
  )
}
