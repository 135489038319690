import React from 'react'
import { useSiteMetadata } from '../../../hooks/useSiteMetadata'

export default function EtherscanLink({ className }: { className?: string }) {
  const { oceanTokenContract } = useSiteMetadata()

  return (
    <a
      href={`https://etherscan.io/token/${oceanTokenContract}`}
      target="_blank"
      rel="noopener noreferrer"
      className={className && className}
    >
      View on Etherscan
    </a>
  )
}
